import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/userContext";
import { useStudio } from "../../../context/studioContext";
import apiTrainer from "../../../api/apiTrainer";
import { getTrainingsByDateRange, getTrainingsForCalendarByHallId } from "./utils/trainings";
import apiTrainingSettings from "../../../api/apiTrainingSettings";

function useLogicTimeTable() {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [list, setList] = useState([]);
    const [trainingsForCalendar, setTrainingsForCalendar] = useState([]);
    const [openedForm, setOpenedForm] = useState('nameForm');
    const [isLoadingFill, setIsLdingFill] = useState(false);
    const [trainers, setTrainers] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState();
    const [selectedHall, setSelectedHall] = useState();
    const [dateRange, setRangeDate] = useState(undefined);
    const [optionsHalls, setOptionsHalls] = useState([]);
    const [selectedTraining, setSelectedTraining] = useState(undefined);
    const [trainingSettings, setTrainingSettings] = useState(undefined);
    const navigate = useNavigate();

    function handleSuccesCreateTraining(trainings) {
        const listWithAddiedTrainings = list.concat(trainings); 
        setList(listWithAddiedTrainings);
        setOpenedForm('');
        //window.location.reload();
    }

    function handleSuccesUpdateTraining(training) {
        const arrayOfObjects = [...list]
        const indexOfObjectToReplace = arrayOfObjects.findIndex(obj => obj._id === training._id);
        if (indexOfObjectToReplace !== -1) {
            // Объект найден, можно заменить его.
            // const newObj = training;
            // arrayOfObjects[indexOfObjectToReplace] = newObj;
            // setList(arrayOfObjects)
            // setOpenedForm('');
            // setSelectedTraining();
            fill();
        }
    }

    function handleSuccessRemoveTraining() {
        const updatedList = list.filter(obj => obj._id !== selectedTraining._id)
        setList(updatedList);
        setOpenedForm('');
        setSelectedTraining();
    }

    function handleTrainingCalendarClick(clickTraining) {
        const findedTraining = list.find(training => training._id === clickTraining.id)
        if (findedTraining) {
            setSelectedTraining(findedTraining);
        }
        setOpenedForm('FormTraining');
    }

    function handleSelectSlot(select) {
        setSelectedSlot(select);
        setOpenedForm('FormTraining');
    }

    function closeForm() {
        setOpenedForm('')
        setTimeout(() => setSelectedTraining(), 350)
    }

    function openTrainingPage(e) {
        //e.preventDefault();
        navigate('/training-page', { state: selectedTraining });
    }

    //ЗАЛЫ
    function fillHalls() {
        const halls = studio.spaces.map((space) => {
            return { value: space._id, label: space.name, capacity: space.capacity, }
        })
        setOptionsHalls(halls)
        setSelectedHall(halls[0].value)
    }
    useEffect(() => {
        if (studio) {
            fillHalls();
        }
    }, [studio])

    //ТРЕНЕРЫ
    async function getTrainersByCompanyBranchId() {
        const trainers = await apiTrainer.getTrainersByCompanyBranchId(studio._id);
        const options = trainers.trainers.map((trainer) => {
            return { value: trainer._id, label: `${trainer.surname} ${trainer.name}` }
        })
        setTrainers(options);
    }
    useEffect(() => {
        getTrainersByCompanyBranchId()
    }, [])

    //НАПОЛНИТЬ ТРЕНИРОВКИ ДЛЯ КАЛЕНДАРЯ В ЗАВИСИМОСТИ ОТ ЗАЛА
    useEffect(() => {
        if (selectedHall && list.length > 0) {
            const trainingsFormatedAndFiltered = getTrainingsForCalendarByHallId({ trainings: list, hallId: selectedHall })
            setTrainingsForCalendar(trainingsFormatedAndFiltered)
        }
    }, [selectedHall, list])

    //ПОЛУЧИТЬ НАСТРОЙКИ ТРЕНИРОВОК
    async function getTrainingSettings() {
        try {
            const data = await apiTrainingSettings.getTrainingSettingsByCompanyBranchId(studio._id)
            if (data.trainingSettings && data.trainingSettings.length < 1) {
                navigate('/training-setting');
                return null;
            }
            const actual = data.trainingSettings.filter((item) => item.isActual)
            const sortedArray = actual.sort((a, b) => {
                if (a.type < b.type) {
                    return -1;
                }
                if (a.type > b.type) {
                    return 1;
                }
                return 0;
            })
            setTrainingSettings(sortedArray);
            return sortedArray;
        }
        catch (err) {
            throw new Error(err);
        }
    }

    //ПОЛУЧИТЬ ОБЩИЙ СПИСОК ТРЕНИРОК
    async function fill() {
        try {
            setOpenedForm('LoaderAbovePage');
            const isTS = await getTrainingSettings();
            if (isTS) {
                const trainings = await getTrainingsByDateRange({ companyBranchId: studio._id, dateRange });
                setList(trainings);
            }
            setOpenedForm();
        }
        catch (err) {
            setOpenedForm();
            setIsLdingFill(false);
        }
    }
    useEffect(() => {
        if (studio) {
            fill()
        }
    }, [studio, dateRange])

    return {
        list,
        openedForm,
        setOpenedForm,
        handleSuccesCreateTraining,
        handleSuccesUpdateTraining,
        handleSuccessRemoveTraining,
        isLoadingFill,
        trainingsForCalendar,
        handleTrainingCalendarClick,
        handleSelectSlot,
        trainers,
        selectedSlot,
        selectedHall, setSelectedHall, optionsHalls,
        setRangeDate,
        selectedTraining, setSelectedTraining,
        trainingSettings,
        openTrainingPage,
        closeForm,
    };
}

export default useLogicTimeTable;
